exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/legal/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-legal-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */)
}

