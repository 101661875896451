import { extendTheme } from "@chakra-ui/react";

import { buttonTheme } from "src/theme/button";
import { customColors } from "src/theme/colors";

export const customTheme = extendTheme({
  colors: customColors,
  fonts: {
    heading: "'Inter Variable', sans-serif",
    body: "'Lato', sans-serif",
  },
  components: { Button: buttonTheme }
})