import { defineStyle, defineStyleConfig  } from "@chakra-ui/react";

const baseStyle = defineStyle({
  borderRadius: "10px",
  fontWeight: 700,
  fontFamily: "Lato",
})

const sizes = {
  md: defineStyle({
    fontSize: "md",
  }),
}

const primaryVariant = defineStyle({
  fontFamily: "Lato",
  bg: "#09174a",
  fontWeight: 700,
  color: "#fff",
  borderRadius: "10px",
  transition: 'transform 0.15s ease-out, background 0.15s ease-out',
  _dark: {
    bg: "#8da3f1",
    color: 'gray.800',
  },

  _hover: {
    transform: "scale(1.05, 1.05)",
    bg: "#0e2372",

    _dark: {
      bg: "#0e2372",
    },
  },

  _disabled: {
    bg: "#09174a",
    color: "#fff",
    cursor: "not-allowed",
  },

  _active: {
    bg: "#09174a",
    transform: "scale(1, 1)",

    _dark: {
      bg: "#3259e6",
    }
  },
})

const secondaryVariant = defineStyle({
  fontFamily: "Lato",
  bg: "#fff",
  fontWeight: 700,
  color: "#09174a",
  borderRadius: "10px",
  transition: 'transform 0.15s ease-out, background 0.15s ease-out',
  _dark: {
    bg: "#fff",
    color: 'gray.800',
  },

  _hover: {
    transform: "scale(1.05, 1.05)",
    bg: "#fff",

    _dark: {
      bg: "#fff",
    },
  },

  _active: {
    bg: "#fff",
    transform: "scale(1, 1)",

    _dark: {
      bg: "#fff",
    }
  },
})

const outlineVariant = defineStyle({
  fontFamily: "Lato",
  bg: "#fff",
  fontWeight: 700,
  color: "#09174a",
  borderRadius: "10px",
  borderWidth: "2px",
  borderColor: "#09174a",
  transition: 'transform 0.15s ease-out, background 0.15s ease-out',
  _dark: {
    bg: "#fff",
    color: 'gray.800',
  },

  _hover: {
    transform: "scale(1.05, 1.05)",
    bg: "#fff",

    _dark: {
      bg: "#fff",
    },
  },

  _active: {
    bg: "#fff",
    transform: "scale(1, 1)",

    _dark: {
      bg: "#fff",
    }
  },
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    primary: primaryVariant,
    secondary: secondaryVariant,
    outline: outlineVariant,
  },
})