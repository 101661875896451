export const customColors = {
  primary: {
    50: "#e8edfc",
    100: "#bbc8f7",
    200: "#8da3f1",
    300: "#607eec",
    400: "#3259e6",
    500: "#1940cd",
    600: "#13319f",
    700: "#0e2372",
    800: "#081544",
    900: "#09174a",
  },
}